
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Emit, Watch} from 'vue-property-decorator';
    import debounce from 'debounce';
    import {
        IQuickSearchResult
    } from '@/types';
    

    @Component<QuickSearchInput>({})
    export default class QuickSearchInput extends Vue {
        public isLoading: boolean = false;
        public items: IQuickSearchResult[] = [];
        public searchText: string|null = null;
        public innerModel: IQuickSearchResult[]|null = null;

        private debouncedAutocompleteQuery = debounce(this.sendAutocompleteQuery, 300);

        private scrollToTop() {
            const isIos = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

            if (isIos) {
                return;
            }

            if (this.$vuetify.breakpoint.smAndDown && this.$route.name === 'home') {
                this.$scrollTo('#top');
            }
        }

        @Watch('searchText')
        private onSearchTextChange(val: string) {
            if (!val) {
                this.items = [];
                return;
            }

            this.isLoading = true;
            this.debouncedAutocompleteQuery(val);
        }

        @Emit('input')
        private onValueChange() {
            return this.innerModel;
        }

        private sendAutocompleteQuery(val: string) {
            return this.$api.search
                .doQuickSearch(val)
                .then((suggestions: IQuickSearchResult[]) => {
                    this.items = suggestions;
                })
                .finally(() => this.isLoading = false)
            ;
        }
    }
