
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Emit, Model, Watch} from 'vue-property-decorator';
    import debounce from 'debounce';

    import {
        IPlacePosition,
        IPlaceSuggestion,
    } from '@/types';

    @Component<PlaceInput>({
        props: {
            geolocation: {
                type: String,
                default: 'true',
            },
        }
    })
    export default class PlaceInput extends Vue {
        public isLoading: boolean = false;
        public items: IPlaceSuggestion[] = [];
        public searchText: string|null = null;
        public innerModel: IPlaceSuggestion|null = null;

        @Model('input', {type: Object}) public readonly value!: IPlaceSuggestion|null;

        private debouncedAutocompleteQuery = debounce(this.sendAutocompleteQuery, 300);

        public get search() {
            return this.searchText ?
                this.searchText :
                this.innerModel ?
                    this.innerModel.title :
                    ''
            ;
        }

        public set search(val: string) {
            if (val) {
                this.searchText = val;
            } else {
                this.searchText = (this.innerModel ? this.innerModel.title : null);
            }
        }

        public handlePermission() {
            navigator.permissions.query({name: 'geolocation'}).then((result) => {
                if (result.state === 'prompt' || result.state === 'granted') {
                    this.queryUserPosition();
                }
            });
        }

        public get hasGeolocation() {
            if(navigator.geolocation)
            return !!navigator.geolocation;
        }

        public getUserLocation() {
            if (navigator.permissions) {
                this.handlePermission();
            } else {
                this.queryUserPosition();
            }
        }

        private scrollToTop() {
            const isIos = [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

            if (isIos) {
                return;
            }

            if (this.$vuetify.breakpoint.smAndDown && this.$route.name === 'home') {
                this.$scrollTo('#top');
            }
        }

        private queryUserPosition() {
            navigator.geolocation.getCurrentPosition((position) => this.setPosition(position.coords));
        }

        private setPosition(coords: IPlacePosition) {
            this.$api.place
                .reverseGeocode(coords)
                .then((suggestion: IPlaceSuggestion) => {
                    this.innerModel = suggestion;
                    this.onAddressChange();
                })
            ;
        }

        private mounted() {
            if (this.value) {
                this.innerModel = this.value;
            }
        }

        @Watch('value')
        private valueChanged(val: IPlaceSuggestion) {
            if (val !== this.innerModel) {
                this.innerModel = val;
            }
        }

        @Watch('searchText')
        private onSearchTextChange(val: string) {
            if (!val) {
                this.items = [];
                return;
            }

            this.isLoading = true;
            this.debouncedAutocompleteQuery(val);
        }

        @Emit('input')
        private onAddressChange() {
            return this.innerModel;
        }

        private sendAutocompleteQuery(val: string) {
            return this.$api.place
                .placeAutocomplete(val)
                .then((suggestions: IPlaceSuggestion[]) => {
                    this.items = suggestions;
                })
                .finally(() => this.isLoading = false)
            ;
        }
    }
